import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";

const InstagramNaked = ({ userName }) => (
    <a
        href={`https://www.instagram.com/${userName}`}
        target="_blank"
        rel="noopener noreferrer"
        className="ml-2"
        aria-label="InstagramIcon"
    >
        <Icon iconName="InstagramIcon" />
    </a>

);

InstagramNaked.propTypes = {
    userName: PropTypes.string.isRequired,
};

export default InstagramNaked;