import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import ContactItem from "components/ContactItem";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, timesWork, timesWork2, address, address2 } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>

      <ContactItem geometry={[56.831231, 35.944226]} timesWork={timesWork} address={address} phone="Евгения: +7 (903) 804 65 72" />
      <hr className="divider my-4" />
      <ContactItem geometry={[56.888467, 35.902049]} timesWork={timesWork2} address={address2} phone="Жанна: +7 (963) 154 32 02" />
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
