import makeFAIcon from "utils/makeFAIcon";

import { faShoppingCart, faLaptop, faLock, faSearch, faHome, faRuler, faCar, faBuilding, faEdit } from "@fortawesome/free-solid-svg-icons";

export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
export const LaptopIcon = makeFAIcon(faLaptop);
export const LockIcon = makeFAIcon(faLock);
export const SearchIcon = makeFAIcon(faSearch);
export const ThumbTackIcon = makeFAIcon(faHome);
export const RulerIcon = makeFAIcon(faRuler);
export const CarIcon = makeFAIcon(faCar);
export const BuildingIcon = makeFAIcon(faBuilding);
export const EditIcon = makeFAIcon(faEdit);