import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const WhatsApp = ({ number }) => (
    <CircleIcon href={`https://wa.me/${number}`} iconName="WhatsAppIcon" />
);

WhatsApp.propTypes = {
    number: PropTypes.string.isRequired,
};

export default WhatsApp;