import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Telegram = ({ number }) => (

    <CircleIcon href={`tg://msg?to=+${number}`} iconName="TelegramIcon" />
    // https://t.me/nickname или tg://resolve?domain=trapwalker
);

Telegram.propTypes = {
    number: PropTypes.string.isRequired,
};

export default Telegram;