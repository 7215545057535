import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Row, Col } from "react-bootstrap";

const ContactItem = ({ timesWork, address, geometry, phone }) => {
    return (
        <Row lg={2} xs={1} className="justify-content-center">
            <Col>
                <YMaps>
                    <Map width="auto" defaultState={{ center: geometry, zoom: 16 }} >
                        <Placemark geometry={geometry} />
                    </Map>
                </YMaps>
            </Col>
            <Col className="my-3 text-center">
                <Row lg={2} xs={1} className="text-center">
                    <Icon iconName="ClockIcon" size="3x" className="col-lg-4 mb-3 text-muted" />
                    <a className="d-block mr-auto text-center col-lg-8">
                        {timesWork}
                    </a>
                </Row>
                <hr className="divider my-2" />
                <Row lg={2} xs={1} className="text-center">
                    <Icon iconName="AdressIcon" size="3x" className="text-muted mb-3 col-lg-4" />
                    <a className="d-block mr-auto mx-auto text-center col-lg-8">
                        {address}
                    </a>
                </Row>
                <hr className="divider my-2" />
                <Row lg={2} xs={1} className="text-center">
                    <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3 col-lg-4" />
                    <a className="d-block text-center text-center col-lg-8">
                        {phone}
                    </a>
                </Row>
            </Col>
        </Row >
    );
};

ContactItem.propTypes = {
    timesWork: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    geometry: PropTypes.array.isRequired,
    phone: PropTypes.string.isRequired
};

export default ContactItem;
