import React from "react";
import PropTypes from "prop-types";

import CircleIcon from "components/CircleIcon";

const Viber = ({ number }) => (
    <CircleIcon href={`viber://chat?number=%2B${number}`} iconName="ViberIcon" />
);

Viber.propTypes = {
    number: PropTypes.string.isRequired,
};

export default Viber;